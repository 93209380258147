@import url('https://fonts.googleapis.com/css2?family=Work+Sans:wght@100;200;300;400;500;600;700&display=swap');

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  margin: 0;
  font-family: "Work Sans", -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.App {
  max-width: 1200px;
  margin: 0 auto;
}

/* ------------------------------------------------------ */
/* -- Navigation -- */

/* header {
  display: flex;
  justify-content: space-between;
  align-items: center;

  padding: 0 25px;
} */

.header,
.about,
.projects,
.experience,
.social,
.footer {
  padding: 50px 25px 25px;
}

/* ------------------------------------------------------ */
/* -- Header -- */

.header {
  display: flex;
  align-items: center;
  flex-wrap: wrap;

  text-align: center;
}

.header img {
  max-width: 300px;
  height: auto;

  margin: 20px auto;

  border-radius: 50%;
}

.header__content {
  width: 400px;
  margin: 0 auto;
}

.header__content h1 {
  font-size: 48px;
}

.header__content p {
  font-size: 32px;
}

.header__content button {
  margin: 25px auto 0;
}

/* ------------------------------------------------------ */
/* -- About -- */

.about h2 {
  font-size: 42px;
  margin: 25px auto;
}

.about p {
  font-size: 18px;
  line-height: 130%;
  max-width: 75ch;
}

/* ------------------------------------------------------ */
/* -- Projects -- */

.projects h2 {
  font-size: 42px;
  margin: 25px auto;
}

.projects p {
  font-size: 18px;
  line-height: 130%;
  max-width: 50ch;
}

.projects__cards {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  gap: 20px;
}

.projects__card {
  max-width: 350px;

  padding: 20px;

  border: 1px solid #0088BB;
  border-radius: 8px;
}

.projects__card h3 {
  font-size: 32px;
  font-weight: 300;
  margin-bottom: 10px;
}

.projects__card p {
  font-size: 16px;
  margin: 20px 0;
}

.projects__card img {
  width: 100%;
  height: auto;
  margin: 20px 0 10px;

  border-radius: 8px;
}

.projects__card .button {
  margin: 10px 5px 0;
}

/* ------------------------------------------------------ */
/* -- Education/Experience -- */

.experience h2 {
  font-size: 42px;
  margin: 25px auto;
}

.experience ul {
  margin: 0 0 10px 50px;
}

.experience li {
  font-size: 18px;
  line-height: 130%;
  margin: 15px auto;
}

/* ------------------------------------------------------ */
/* -- Social Media -- */

.social h2 {
  font-size: 42px;
  margin: 25px auto;
}

.social .button {
  margin: 0 15px;
}

/* ------------------------------------------------------ */
/* -- Footer -- */
.footer {
  margin: 0 auto;
  text-align: center;
}

/* ------------------------------------------------------ */
/* -- HTML COMPONENTS: BUTTON -- */
.button {
  display: inline-block;

  background: #12BDF3;

  padding: 15px 50px;

  border: none;
  border-radius: 8px;

  font-family: "Work Sans";
  font-size: 16px;
  font-weight: 700;
  text-decoration: none;

  cursor: pointer;

  transition: all 250ms ease;
}

.button:hover {
  background: #0088BB;
  color: #FFFFFF;
}